<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      outlined
      class="project-card rounded-10"
      v-bind="$attrs"
      :elevation="hover ? 5 : 4"
    >
      <v-card-title class="card-header">
        <Avatar
          v-if="item.client"
          :user="item.client"
          :display="item.client.fullname"
        >
        </Avatar>
        <Avatar v-else :user="item.creator" :display="item.creator.fullname">
        </Avatar>
        <v-spacer />
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" v-on="on"> mdi-dots-vertical </v-icon>
          </template>
          <v-list dense>
            <v-list-item
              v-if="can_edit && isEditable"
              @click.stop="$emit('edit')"
            >
              <v-list-item-title class="primary--text">
                <v-icon color="primary" left>mdi-circle-edit-outline</v-icon>
                Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="can_delete && isDeletable"
              @click.stop="$emit('delete')"
            >
              <v-list-item-title class="primary--text">
                <v-icon color="primary" left>mdi-delete-circle-outline</v-icon>
                Delete
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="navigateToProject">
              <v-list-item-title class="primary--text">
                <v-icon color="primary" left>mdi-eye-circle-outline</v-icon>
                View
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text class="text-center card-body">
        <h4
          class="project-title cursor-pointer mb-2"
          @click="navigateToProject"
        >
          {{ item.title | ucwords }}
        </h4>
        <h4
          v-if="item.client_company"
          class="cursor-pointer primary--text fw-700 mb-2"
          @click="navigateToProject"
        >
          {{ item.client_company.name || '' | ucwords }}
        </h4>
        <h4
          v-else
          class="cursor-pointer primary--text fw-700 mb-2"
          @click="navigateToProject"
        >
          {{ item.creator.company.name || '' | ucwords }}
        </h4>
        <p class="caption font-weight-bold">
          {{ item.started_at | format }} -
          <span v-if="item.end_at">{{ item.end_at | format }}</span>
          <span v-else>Ongoing</span>
        </p>
        <v-row dense no-gutters>
          <v-col>
            <h6 class="text-center font-weight-bold caption">Managers</h6>
            <Avatars :items="item.managers" :count="1" />
          </v-col>
          <v-col>
            <h6 class="text-center font-weight-bold caption">Members</h6>
            <Avatars :items="item.members" :count="1" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row dense class="text-center" align="center" justify="space-around">
          <v-col cols="5">
            <h2 class="font-weight-bold primary--text">
              {{ item.milestones_count || 0 }}
            </h2>
            <h5 class="primary--text">Milestones</h5>
          </v-col>
          <v-col cols="5">
            <h2 class="font-weight-bold primary--text">
              {{ item.tasks_count || 0 }}
            </h2>
            <h5 class="primary--text">Tasks</h5>
          </v-col>
          <v-btn fab dark small color="primary" icon @click="expand = !expand">
            <v-icon>
              {{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="expand" class="description-wrapper">
          <v-divider />
          <v-card-text v-if="item.description">
            <label class="primary--text mb-1 subtitle-2"> Description: </label>
            <p v-html="item.description"></p>
          </v-card-text>
          <Empty v-else headline="No description"></Empty>
        </div>
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectCardPersonal',
  props: {
    item: { type: Object, required: true },
    isEditable: { type: Boolean, default: false },
    isDeletable: { type: Boolean, default: false },
    permission: { type: Object, required: true }
  },
  data: () => ({
    expand: false
  }),
  computed: {
    ...mapGetters(['user']),
    can_edit() {
      if (this.user.is_admin) return true
      if (!this.item.managers) return false
      let found = this.item.managers.find((ii) => ii.id === this.user.id)
      return !!found || this.permission.create
    },
    can_delete() {
      if (this.user.is_admin) return true
      if (!this.item.managers) return false
      let found = this.item.managers.find((ii) => ii.id === this.user.id)
      return !!found || this.permission.update
    }
  },
  methods: {
    navigateToProject() {
      this.$router
        .push({
          name: 'hq',
          params: { id: this.item.id, type: 'project' }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.project-card {
  .card-body {
    color: $darkBlue;
    min-height: 170px;
    .project-title {
      font-weight: bolder;
      font-size: 20px;
      color: $blue;
    }
  }
}
</style>
