<template>
  <v-card class="rounded-10" outlined min-height="500">
    <v-card-title>
      <h2 class="primary--text heading font-weight-bold">Projects</h2>
    </v-card-title>
    <v-card-text>
      <v-progress-linear
        class="mt-2"
        v-show="loading_items"
        :indeterminate="true"
      />
      <v-row dense v-if="items.length > 0">
        <v-col
          :cols="mdAndUp ? 4 : 12"
          class="pa-4"
          v-for="item in items"
          :key="item.id"
        >
          <v-hover v-slot:default="{ hover }">
            <ProjectCardPersonal
              :item="item"
              v-if="item.type === 'personal'"
              :permission="$_permissions.get('projects')"
              :elevation="hover ? 5 : 1"
              :is-editable="false"
              :is-deletable="false"
            ></ProjectCardPersonal>
            <ProjectCardBusiness
              :item="item"
              v-else
              :permission="$_permissions.get('projects')"
              :elevation="hover ? 5 : 1"
              :is-editable="false"
              :is-deletable="false"
            ></ProjectCardBusiness>
          </v-hover>
        </v-col>
      </v-row>
      <Empty
        v-else
        icon="mdi-alpha-p-box-outline"
        class="my-3"
        headline="No project yet"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import ProjectCardPersonal from '@/modules/Projects/components/ProjectCardPersonal.vue'
import ProjectCardBusiness from '@/modules/Projects/components/ProjectCardBusiness.vue'
import { mapGetters } from 'vuex'
export default {
  mixins: [fetch_services],
  components: {
    ProjectCardPersonal,
    ProjectCardBusiness
  },
  data: () => ({
    //
  }),
  created() {
    this.loadItems(
      `api/projects/user-projects/${this.view_user.id}?all=true`,
      false,
      null,
      null
    )
  },
  computed: {
    ...mapGetters('memberProfile', ['view_user'])
  },
  methods: {
    navigate_to_view_project(id) {
      this.$router.push({
        name: 'hq',
        params: { id: id, type: 'project' }
      })
    }
  }
}
</script>

<style></style>
