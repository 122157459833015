import { render, staticRenderFns } from "./ProjectCardPersonal.vue?vue&type=template&id=5cae401b&scoped=true&"
import script from "./ProjectCardPersonal.vue?vue&type=script&lang=js&"
export * from "./ProjectCardPersonal.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCardPersonal.vue?vue&type=style&index=0&id=5cae401b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cae401b",
  null
  
)

export default component.exports